<template>
  <section class="modal">
    <section class="modal-background" @click="$emit('closeGalleryModal')" />
    <section class="modal-content">
      <section class="columns is-multiline">
        <section class="column is-12-tablet is-12-mobile">
          <article class="imageWrapper">
            <v-lazy-image
              class="image"
              :src="require(`@/assets/images/gallery/${modalData.imageFull}`)"
            />
          </article>
          <button class="gallery-button" @click="$emit('prevImage')">
            <span class="left-icon"></span>
          </button>
          <button class="gallery-button" @click="$emit('nextImage')">
            <span class="right-icon"></span>
          </button>
        </section>
      </section>
    </section>
    <button
      class="modal-close is-large"
      @click="$emit('closeGalleryModal')"
      aria-label="close"
    ></button>
  </section>
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'GalleryModal',
  props: ['modalData'],
  components: {
    VLazyImage,
  },
};
</script>

<style lang="scss" scoped>
$modal-background: hsla(0, 0%, 16%, 0.33);

.columns {
  margin: 0 auto;
}

.modal {
  z-index: 50;
}

@media only screen and (max-width: 768px) {
  .column {
    padding: 0 0.75rem 0 0.75rem;
  }
}

@media only screen and (min-width: 769px) {
  .column {
    padding: 0.75rem 0 0.75rem 0;
  }
  .modal-content {
    width: calc(1125px * 0.6);
  }
  .image {
    width: 100%;
  }
}

.imageWrapper {
  width: 100%;
}

.modal-background {
  background-color: $modal-background;
}

.gallery-button {
  width: 2.1rem;
  height: 2.1rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: $white-bis;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $primary;
    outline: none;
  }
}

.left-icon {
  background: url('../../assets/images/chevron-left-solid.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: middle;
  text-align: center;
}

.right-icon {
  background: url('../../assets/images/chevron-right-solid.svg') no-repeat
    center;
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: middle;
  text-align: center;
}
</style>
